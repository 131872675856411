/* Vidhi Desai – 1002081553, Sai Raghu Rami Reddy Dontireddy - 1002014523, 
Abhishek Rajiv Doshi - 1002083080, Rajvi Pankaj Doshi – 1002037078,  
Thulaseeswara Reddy Dudyala - 1002033079*/


body {
  margin: 0;
  /* Default font family for the whole page */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* Smoothing for better font rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 16px;
  margin: 0;
  padding: 0; */

}


/* Code block font family for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Styles for the main app container */
.App {
  text-align: center;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Background color for the whole app */
}

/* Homepage section styles */
.homepage {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}

/* Home div styles with a color of white and height of 600px */
.home-div {
  width: 100%;
  height: 600px;
  display: flex;
  color: black;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Home text styles */
.home-text {
  font-size: 40px;
  /* Font family for the home text */

  text-align: left;
  padding: 40px;
}

.home-textP {
  font-size: 30px;
  /* Font family for the home text */

  text-align: left;
  padding: 40px;
}



/* Header section styles */
.header {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #d1175e;
  /* Background color for the header */
  color: white;
  position: fixed;
  /* Fixed position to keep it at the top */
  top: 0;
  /* Stick it to the top of the viewport */
  z-index: 1000;
  /* Ensure it appears above other content */


}

/* Header right section styles */
.header-right {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #d1175e;
  /* Background color for the header */
  color: white;
  align-items: end;
  justify-content: end;
  font-size: medium;
  text-decoration: double;
  justify-content: flex-end;
}

/* Header left section styles */
.header-left {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #d1175e;
  /* Background color for the header */
  color: white;
  align-items: start;
  justify-content: start;
  font-weight: bold;
  justify-content: flex-start;
}

/* Header title styles */
.header-title {
  margin: 10px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px;
  color: white;
  text-decoration: none;
  /* Color: White */
}

/* Heading styles */
.heading {
  margin-top: -200px;
  padding-left: 5px;
  font-size: 30px;
  text-decoration: double;
  /* Font: Default (Inherited) */
  /* Color: White */
}

.about-text {
  padding: 50px;
  text-align: justify;
  font-size: x-large;
  /* Setting the font size to 'x-large' (larger than the default font size) */
  word-spacing: 5px;
  letter-spacing: 1px;
  line-height: 35px;
}

.about-text-footer {
  padding: 5px;
  text-align: justify;
  font-size: medium;
  /* Setting the font size to 'x-large' (larger than the default font size) */
}

/* Footer section styles */
.footer {
  background-color: #343634;
  /* Background color for the footer */
  color: #fff;
  padding: 2px 0;
  text-align: center;
  /* max-height: 250px;
  position: bottom; */
}

/* Footer content wrapper */
.footer-content {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #fff;
  padding-top: 10px;
}


/* Footer left section styles */
.footer-left {
  flex: 0.5;
  /* Take up available space */
  text-align: left;
  padding: 5px;
  font-size: medium;
  /* Setting the font size to 'medium' */
}

/* Footer right section styles */
.footer-right {
  flex: 1.5;
  /* Take up available space */
  display: flex;
  flex-direction: column;
  /* Stack content vertically */
  align-items: end;
  /* Center align content horizontally */
}

/* Footer left section styles */
.footer-left {
  flex: 0.5;
  /* Take up available space */
  text-align: left;
  padding: 5px;
  font-size: medium;
  /* Setting the font size to 'medium' */
}

/* Footer right section styles */
.footer-right {
  flex: 1.5;
  /* Take up available space */
  display: flex;
  flex-direction: column;
  /* Stack content vertically */
  align-items: end;
  /* Center align content horizontally */
}

/* Footer links styles */
.footer-links {
  display: flex;
  font-size: medium;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

/* Footer links title styles */
.footer-links-title {
  margin-right: 40px;
  margin-left: 20px;
  padding-top: 10px;
  color: white;
  text-decoration: none;
}


/* Social media icons styles */
.footer-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* Add margin for spacing between links and social icons */
}

.icons {
  margin: 0 10px;
  /* Add margin between icons */
}

.aboutUs-container {
  max-width: 800px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Styles for the services page */

.services {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 100px;
}

.services h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.services p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.services ul {
  list-style-type: none;
  /* Remove bullet points */
  text-align: center;
  margin-left: 20px;
  padding-left: 0;
  /* Remove default padding */
}

.services li {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}


/* Footer copyright text styles */

/* Slideshow container */
/* .slideshow-container {
  max-width: 70%;
  padding-top: 50px;
  position: relative;
  margin: auto;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
} 


@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px
  }
}

@media (max-width: 768px) {
  img {
    max-height: 50vh;
   
  }
}

@media (min-width: 769px) {
  img {
    max-height: 70vh;
  }
}


/* Styles for the chat and feedback feature */

.chat-feedback {
  /* position: fixed;
  bottom: auto;
  right: 20px;
  display: flex;
  align-items: center; */
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.chat-icon img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
}

/* Styles for the specific <a> element */
.chat-hover {
  text-decoration: none;
  color: #000;
  position: relative;
  /* Add this to establish a positioning context */
}

/* CSS pseudo-element for the hover effect */
.chat-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #343634(0, 0, 0, 0);

}

/* Hover effect for the <a> element */
.chat-hover:hover::before {
  background: #343634;
  /* Adjust the background color and opacity as desired */
}


.feedback-button {
  background-color: #d1175e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.feedback-button:hover {
  background-color: #8f0a57;
}

.feedback-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #d1175e;
  /* Button background color */
  color: #fff;
  /* Button text color */
  text-decoration: none;
  border-radius: 5px;
  /* Rounded corners */
  transition: background-color 0.3s ease;
}

/* Hover effect for the "Feedback" link */
.feedback-link:hover {
  background-color: #8f0a57;
  /* Change background color on hover */
}

/* Adjust the styles as needed to match your website's design and branding. */

a:hover {
  background-color: transparent;
  text-decoration-color: whitesmoke;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-title {
  margin-right: 10px;
  text-decoration: none;
  color: whitesmoke;
}

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown menu (hidden by default) */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Dropdown menu items */
.dropdown-menu a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Sign up page*/
.container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  margin-bottom: 50px;
  margin-top: 80px; */
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: auto;
  /* display: flex; */
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.form-container {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 50px;
  border-radius: 5px;
  text-align: center;
}

.form-container_admin {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

form {
  display: flex;
  flex-direction: column;
}

h2 {
  margin-bottom: 15px;
}

input[type="text"],
input[type="text"],
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  margin-bottom: 15px;
}

.button-links {
  position: relative;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

/* Remove the hover effect that changes the background color */
.button-links:hover {
  background-color: transparent; /* Set it to transparent to remove the hover background effect */
}

button {
  position: relative;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: blue;
}

button:hover {
  background-color: blue;
}

.signup-checkbox {
  text-align: left;
  font-size: x-small;
  margin-top: 2px;

}

.form-group {
  margin-bottom: 15px;
  text-align: left;

}

.label {
  display: block;
  font-weight: bold;
  position: fixed;
}

.contact-details-list {
  list-style: none;
  /* Remove default list bullets */
  padding-left: 0;
  /* Remove default list padding */
}

.highlighted-text {
  background-color: #ffcc00;
  /* Yellow background */
  padding: 5px;
  /* Add padding for better visual appeal */
  font-weight: bold;
  /* Make the text bold */
}


.chat-container {
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;
}

.message {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.user1-message {
  background-color: #e6f7ff;
  /* Light blue background */
}

.user2-message {
  background-color: #f2f2f2;
  /* Light gray background */
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}

#message-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  outline: none;
}

#send-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

/* QA OFFICER */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700"); */
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.qa-container {
  display: flex;
  max-width: 100%;
  margin-top: 70px;

}

nav {
  position: relative;
  top: 0;
  bottom: 0;
  height: 500px;
  left: 0;
  background: #fff;
  width: 400px;
  overflow: hidden;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}

.logo {
  text-align: center;
  display: flex;
  margin: 10px 0 0 10px;
  
}
.logo:hover{
  background-color: transparent;
}

.logo img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

/* img {
  width: 70%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-left: 100px;
  margin-right: 100px;
} */
 .qa-img {
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
} 
.logo span {
  font-weight: bold;
  padding-left: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.qa-link {
  position: relative;
  color: black;
  font-size: 14px;
  display: table;
  width: 280px;
  padding: 10px;
}

.qa-link:hover {
  background-color: transparent  ;

}

nav .fas {
  position: relative;
  width: 70px;
  height: 40px;
  top: 14px;
  font-size: 20px;
  text-align: center;
}

.nav-item {
  position: relative;
  top: 12px;
  margin-left: 10px;
  color: white;
  background-color: white;
}
.nav-item:hover {
  background-color: transparent;
  
}
.logout {
  position: absolute;
  bottom: 0;
}

/* Main Section */
.main {
  position: relative;
  padding: 20px;
  width: 100%;
}

.main-top {
  display: flex;
  width: 100%;
}

.main-top i {
  position: absolute;
  right: 0;
  margin: 10px 30px;
  color: rgb(110, 109, 109);
  cursor: pointer;
}

.main-skills {
  display: flex;
  margin-top: 20px;
}

.main-skills .card {
  width: 25%;
  margin: 10px;
  background: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}

.main-skills .card h3 {
  margin: 10px;
  text-transform: capitalize;
}

.main-skills .card p {
  font-size: 12px;
}

.main-skills .card button {
  background: orangered;
  color: #fff;
  padding: 7px 15px;
  border-radius: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.main-skills .card button:hover {
  background: rgba(223, 70, 15, 0.856);
}

.main-skills .card i {
  font-size: 22px;
  padding: 10px;
}

/* Courses */
.main-course {
  margin-top: 20px;
  text-transform: capitalize;
}

.course-box {
  width: 100%;
  height: 300px;
  padding: 10px 10px 30px 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}

.course-box ul {
  list-style: none;
  display: flex;
}

.course-box ul li {
  list-style-type: none;
  padding: 10px;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  /* Increase the space between buttons vertically */
}

.course-box ul li:hover {
  background-color: #007bff;
  color: gray;
  cursor: pointer;
}

.course-box ul .active {
  color: #000;
  border-bottom: 1px solid #000;
}

.course-box .course {
  display: flex;
}

.box {
  width: 33%;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background: rgb(235, 233, 233);
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}

.box p {
  font-size: 12px;
  margin-top: 5px;
}

.box button {
  background: #000;
  color: #fff;
  padding: 7px 10px;
  border-radius: 10px;
  margin-top: 3rem;
  cursor: pointer;
}

.box button:hover {
  background: rgba(0, 0, 0, 0.842);
}

.box i {
  font-size: 7rem;
  float: right;
  margin: -20px 20px 20px 0;
}

.html {
  color: rgb(25, 94, 54);
}

.css {
  color: rgb(104, 179, 35);
}

.js {
  color: rgb(28, 98, 179);
}

/* QA Policy Management */

.policy-table {
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  overflow-x: auto;

}

.policy-table th,
.policy-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.policy-table th {
  background-color: #007bff;
  color: #fff;
}

.policy-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* .policy-table td:hover {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
} */
/* Report container */

.report-container {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 50px;
  margin-top: 50px;
}

.userType {
  width: 100%;
  /* Use a percentage value for full width or adjust as needed */
  padding: 8px;
  /* Adjust padding to control the height of the dropdown */
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Reset some default styles */
body,
h1,
h2,
form {
  margin: 0;
  padding: 0;
}

/* Style for the container */
.setting_container {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  margin: 0 auto;
  padding: 20px;
  margin-top: 70px;
}

/* Style for profile information */
.profile {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.profile p {
  margin: 10px 0;
}

.main-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

/* Mobile */
@media (max-width: 480px) {
  /* Previous styles for mobile screens */

  .footer {
    padding: 2px 0;
    text-align: center;
  }
   .main-container {
    padding-top: 70px; /* Adjust the value as needed */
  }

  .footer-content {
    flex-direction: column;
    padding-top: 10px;
  }

  .footer-left,
  .footer-right {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .footer-links-title {
    margin: 10px 0;
  }

  .footer-social-icons {
    margin-top: 10px;
  }

  .icons {
    margin: 0 5px;
  }

  /* Adjusted styles for the card class on mobile */
  .card {
    width: 90%;
    /* Adjust the width to fit within the mobile screen */
    margin: 10px auto;
    border-radius: 10px;
    background: rgb(235, 233, 233);
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
  }

  .card p {
    font-size: 12px;
    margin-top: 5px;
  }

  .card button {
    background: #000;
    color: #fff;
    padding: 7px 10px;
    border-radius: 10px;
    margin-top: 1rem;
    cursor: pointer;
    width: 100%; /* Adjust button width to fit the card */
  }

  .card i {
    font-size: 5rem;
    margin: 10px auto;
  }

  /* Updated styles for the header in mobile view */


  .chart {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .policy-table {
    overflow-x: auto;
  }
  .main-skills {
    flex-direction: column;
  }

  .main-skills .card {
    width: 80%; /* Adjust the width as needed */
    margin: 10px auto;
  }
  
  
}
img {
  max-width: 100%;
  /* Ensure images do not overflow their parent containers */
  height: auto;
  /* Maintain the aspect ratio of the images */
}

/* Quiz related CSS  */

/* App container */
/* .App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 20px;
}

/* Exam container */
/* .Exam {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Question container */
/* .Question {
  background-color: #fff;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
} */

/* Timer display */
/* .Timer {
  font-size: 24px;
  margin: 10px 0;
}

/* Answer options */
/* .AnswerOption {
  list-style: none;
  padding: 0;
  margin: 10px 0;
} */ 

/* .AnswerOption li {
  display: flex;
  align-items: center;
} */

/* .AnswerOption input[type="radio"] {
  margin-right: 10px;
} */

/* Submit button */
/* .SubmitButton {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
} */

/* .SubmitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} */

/* Popup container */
/* .Popup {
  background-color: #fff;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.Popup p {
  font-size: 16px;
  margin: 0 0 10px;
} */

/* .Popup button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Popup button:hover {
  background-color: #0056b3;
}  */

QuizForm.css

.quiz-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quiz-title {
  text-align: center;
  color: #333;
}

.quiz-form {
  margin-top: 20px;
}

.question-input,
.answer-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.answer-list {
  list-style-type: none;
  padding: 0;
}

.answer-list li {
  margin-bottom: 10px;
}

.add-question-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.add-question-button:hover {
  background-color: #45a049;
}

.quiz-preview {
  margin-top: 20px;
}

.question-list {
  list-style-type: none;
  padding: 0;
}

.question-list li {
  margin-bottom: 20px;
}

.question-text {
  font-weight: bold;
  margin-bottom: 10px;
}

.save-quiz-button {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.save-quiz-button:hover {
  background-color: #007ba7;
}
